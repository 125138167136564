import Vue from 'vue'
import VueRouter from 'vue-router'
// import pinia from '@/store/pinia'
import account from '@/router/account'
import accounting from '@/router/accounting'
import accountingTemplates from '@/router/accounting-templates'
import analytics from '@/router/analytics'
import crm from '@/router/crm'
import desk from '@/router/desk'
import exportsReports from '@/router/exports'
import fixedAssets from '@/router/fixed-assets'
import hiddenViews from '@/router/hidden-views'
import hub from '@/router/hub'
import inventories from '@/router/inventories'
import items from '@/router/items'
import logs from '@/router/logs'
import orderDispatchs from '@/router/order-dispatchs'
import payrollLite from '@/router/payroll-lite'
import people from '@/router/people'
import playStore from '@/router/play-store'
import pos from '@/router/pos'
import productions from '@/router/manufacture'
import publicViews from '@/router/public'
import purchases from '@/router/purchases'
import sales from '@/router/sales'
import setups from '@/router/settings'
import treasuries from '@/router/treasuries'
import { i18n } from '@/plugins/i18n'
// import home from '@/router/home'
// import { useLayoutStore } from '@/store/pinia/layoutStore'
import { useStore } from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: i18n.tc('Start')
    },
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    meta: {
      title: 'Forbidden'
    },
    path: '/forbidden',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '@/views/Forbidden.vue')
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    meta: {
      title: 'New Client'
    },
    path: '/client/new',
    name: 'client-new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue')
  },
  {
    meta: {
      title: 'Edit Client'
    },
    path: '/client/:id',
    name: 'client-edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Login',
      layout: 'layout-blank'
    },
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    meta: {
      title: 'Login Aldd',
      layout: 'layout-blank'
    },
    path: '/login/aldd',
    name: 'login-aldd',
    component: () => import(/* webpackChunkName: "login-aldd" */ '@/views/LoginAldd.vue')
  },
  // Verificación de emails
  {
    meta: {
      title: 'ConfirmEmail',
      requiresAuth: false,
      layout: 'layout-blank'
    },
    path: '/confirm/email/:userId/:code',
    name: 'confirm-email',
    component: () => import(/* webpackChunkName: "confirm-email" */ '@/views/ConfirmEmail.vue')
  },
  /** PUBLIC LAYOUT */
  {
    meta: {
      title: 'Password Recovery',
      requiresAuth: false,
      layout: 'layout-blank'
    },
    path: '/password-recovery',
    name: 'password-recovery',
    component: () => import(/* webpackChunkName: "password-recovery" */ '@/views/PasswordRecovery.vue')
  },
  {
    meta: {
      title: 'Asignar nuevo password',
      requiresAuth: false,
      layout: 'layout-blank'
    },
    path: '/users/:id/set-password',
    name: 'set-user-password',
    component: () => import(/* webpackChunkName: "set-user-password" */ '@/views/SetUserPassword.vue')
  },
  {
    meta: {
      title: 'Error v.1',
      requiresAuth: false,
      layout: 'layout-basic'
    },
    path: '/error-in-card',
    name: 'error-in-card',
    component: () => import(/* webpackChunkName: "error-v1" */ '@/views/Error404.vue')
  },
  {
    meta: {
      title: 'Error 404',
      layout: 'layout-basic'
    },
    path: '*',
    name: 'error-404',
    component: () => import(/* webpackChunkName: "error-404" */ '@/views/Error404.vue'),
    props: { isInCard: false }
  },
  {
    meta: {
      title: 'Kick Off',
      requiresAuth: false,
      layout: 'layout-basic'
    },
    path: '/kickOff',
    name: 'kick-off',
    component: () => import(/* webpackChunkName: "kick-off" */ '@/views/KickOff.vue')
  },
  {
    meta: {
      title: i18n.tc('Import', 2)
    },
    path: '/import',
    name: 'import',
    component: () => import(/* webpackChunkName: "import" */ '@/views/import/Index.vue')

  },
  {
    meta: {
      title: i18n.tc('Survey', 2),
      requiresAuth: false,
      layout: 'layout-blank'
    },
    path: '/survey/response',
    name: 'response-survey',
    component: () => import(/* webpackChunkName: "response-survey" */ '@/views/desk/settings/surveys/ResponseSurvey.vue')
  },
  ...account,
  ...accounting,
  ...accountingTemplates,
  ...analytics,
  ...crm,
  ...desk,
  ...exportsReports,
  ...fixedAssets,
  ...hiddenViews,
  ...hub,
  ...inventories,
  ...items,
  ...logs,
  ...orderDispatchs,
  ...payrollLite,
  ...people,
  ...playStore,
  ...pos,
  ...productions,
  ...publicViews,
  ...purchases,
  ...sales,
  ...setups,
  ...treasuries
  // ...home
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => savedPosition || { x: 0, y: 0 }
})

/**
  * AUTH GUARDIAN
  */
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('accessToken') || false
  const requiresAuth = 'requiresAuth' in to.meta ? to.meta.requiresAuth : true
  const store = useStore()
  const layout = 'layout' in to.meta ? to.meta.layout : 'layout-content'

  store.commit('SET_LAYOUT', layout)

  if (!requiresAuth) return next()

  // Redirect to login if not logged in
  if (!isAuthenticated && to.name !== 'login' && to.name !== 'login-aldd') {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router

export function useRouter () {
  return router
}

export function useRoute () {
  return router.app.$route
}
