// eslint-disable-next-line camelcase
// import axios from '@/plugins/axios'
// import keyBy from 'lodash/keyBy'
import { Buffer } from 'buffer'
export default {
  namespaced: true,
  state: {
    features: localStorage.getItem('features') ? JSON.parse(Buffer.from(localStorage.getItem('features'), 'base64').toString('utf-8')) : [],
    subscriptionId: localStorage.getItem('subscriptionId') ? Buffer.from(localStorage.getItem('subscriptionId'), 'base64').toString('utf-8') : null,
    planLimits: localStorage.getItem('planLimits') ? JSON.parse(Buffer.from(localStorage.getItem('planLimits'), 'base64').toString('utf-8')) : null
  },
  mutations: {
  },
  actions: {
    // setFeatures ({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     axios.get(`${process.env.VUE_APP_API_HUB}subscriptions/${state.subscriptionId}/features`, {
    //       params: { key: process.env.VUE_APP_HUB_API_KEY }
    //     }).then((response) => {
    //       commit('SET_FEATURES', response.data)
    //       resolve(response)
    //     }).catch(error => reject(error))
    //   })
    // },
    // setSubscriptionId ({ commit }, payload) {
    //   commit('SET_ID', payload)
    // },
    // setProfileId ({ commit }, payload) {
    //   commit('SET_PROFILE_ID', payload)
    // }
  },
  getters: {
    getFeatures (state) {
      return state.features
    },
    getPlan (state) {
      return state.planLimits
    },
    getFeaturesAvailableInStore (state) {
      return state.features.filter(i => i.availableInStore)
    },
    getSubscriptionId (state) {
      return state.subscriptionId
    }
  }
}
