export default [
  {
    meta: {
      title: 'Vista de configuración cuenta'
    },
    path: '/hub/account',
    name: 'hub-my-account',
    component: () => import(/* webpackChunkName: "items-view" */ '@/views/hub/Index.vue')
  },
  {
    meta: {
      title: 'Tarjetas suscritas'
    },
    path: '/hub/payment-means',
    name: 'hub-card-subscribed',
    component: () => import(/* webpackChunkName: "items-view" */ '@/views/hub/PaymentMean.vue')
  }
]
